@function calculate-high-contrast-colors($colors, $high-contrast) {
    $high-contrast-merged: ();
    @each $color in $colors {
        $key: nth($color, 1);
        $value: nth($color, 2);
        $high-contrast-value: map-get($high-contrast, $key);

        $new-color: (
            $key:
                if(
                    $high-contrast-value,
                    $high-contrast-value,
                    darken($value, 30%)
                )
        );
        $high-contrast-merged: map-merge($high-contrast-merged, $new-color);
    }

    @return $high-contrast-merged;
}

@function color($color) {
    @return map-get($colors, $color);
}

@mixin themify() {
    $temp_colors: $colors;
    $high-contrast-merged: calculate-high-contrast-colors(
        $colors,
        $high-contrast
    );

    & {
        @content;
    }

    .high-contrast &,
    .high-contrast :host &,
    .high-contrast ~ * & {
        $colors: $high-contrast-merged !global;
        $outline-width: 5px !global;
        @content;
    }

    $colors: $temp_colors !global;
}
