.pex-input {
    font-size: 25px;
    color: #4a4a4a;
    border: none;
    border-bottom: 1px solid #dcdcdc;
    display: block;
    padding: 8px 0;
    width: 100%;
    background: transparent;

    &--small {
        font-size: 18px;
        // color: #b6b4b4;
    }

    &--small-light {
        color: #b6b4b4;
    }

    &:focus {
        @include themify() {
            outline-width: $outline-width;
        }
    }

    &[disabled] {
        cursor: not-allowed;
        background: lightgray;
    }
}

.pex-input-label {
    font-size: 25px;
    color: #4a4a4a;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;

    &--small {
        top: -12px;
        font-size: 13px;
    }

    &--small-light {
        color: #b6b4b4;
    }

    .pex-input:focus ~ &,
    .pex-input:valid ~ &,
    .pex-input:disabled ~ &,
    .pex-input.ng-invalid.ng-touched ~ & {
        top: -21px;
        font-size: 15px;

        &--small {
            top: -12px;
            font-size: 13px;
        }
    }

    .pex-input:focus ~ & {
        @include themify() {
            color: color(blue);
        }
    }

    .pex-input.ng-invalid.ng-touched ~ &,
    .ng-submitted .pex-input.ng-invalid ~ & {
        color: #ff455c;
    }
}

.pex-input-bar {
    position: relative;
    display: block;
    width: 100%;

    &:before,
    &:after {
        content: '';
        height: 2px;
        width: 0;
        bottom: 0;
        position: absolute;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;

        @include themify() {
            background: color(blue);
        }
    }

    &:before {
        left: 50%;
    }

    &:after {
        right: 50%;
    }

    .pex-input:focus ~ &:before,
    .pex-input:focus ~ &:after,
    .pex-input.ng-invalid.ng-touched ~ &:before,
    .pex-input.ng-invalid.ng-touched ~ &:after {
        width: 50%;
    }

    .pex-input.ng-invalid.ng-touched ~ &:before,
    .pex-input.ng-invalid.ng-touched ~ &:after,
    .ng-submitted .pex-input.ng-invalid ~ &:before,
    .ng-submitted .pex-input.ng-invalid ~ &:after {
        background: #ff455c;
    }
}

.pex-input-error {
    font-size: 12px;
    line-height: 1;
    display: block;
    color: #ff455c;
    position: relative;
    top: 9px;
}
